<div fxLayout="column" style="height: 100%">
    <div>
        <div
            *ngIf="data?.banner?.layout === '1-col-medium-strip-banner'"
            class="medium-extra-container"
            fxShow.gt-xs="false"
            [ngStyle.xs]="{ paddingTop: '0%' }"
            (click)="
                data?.banner.link.includes('http://')
                    ? externalLinksRedirect(data?.banner.link)
                    : processLink.emit(data?.banner.link)
            "
        >
            <picture
                class="medium-extra-strip"
                *ngIf="!data?.banner?.video"
                [ngClass]="{ 'image-offer-shimmer': showShimmer }"
            >
                <img
                    class="medium-extra-strip__image"
                    src="{{ data?.banner?.image }}"
                    appImageCDN
                />
            </picture>
            <video
                *ngIf="data?.banner?.video"
                poster="{{ data?.banner?.image }}"
                style="
                    border: none;
                    position: absolute;
                    top: 0;
                    height: auto;
                    width: 100%;
                "
                loop
                muted
                autoplay
                oncanplay="this.play()"
                onloadedmetadata="this.muted = true"
                playsinline
                webkit-playsinline
            >
                <source src="{{ data?.banner?.video }}" type="video/webm" />
                <!-- <source
                        [src]="convertWebToMp4(item?.layoutContent?.banner?.video)"
                        type="video/mp4"
                    /> -->
            </video>
        </div>
        <div
            *ngIf="data?.banner?.layout === '1-col-extra-offer-strip-banner'"
            class="offer-extra-container"
            fxShow.gt-xs="false"
            [ngStyle.xs]="{ paddingTop: '0%' }"
            (click)="
                data?.banner.link.includes('http://')
                    ? externalLinksRedirect(data?.banner.link)
                    : processLink.emit(data?.banner.link)
            "
        >
            <picture
                class="offer-extra-strip"
                *ngIf="!data?.banner?.video"
                [ngClass]="{ 'image-offer-shimmer': showShimmer }"
            >
                <img
                    class="offer-extra-strip__image"
                    src="{{ data?.banner?.image }}"
                    appImageCDN
                />
            </picture>
            <video
                *ngIf="data?.banner?.video"
                poster="{{ data?.banner?.image }}"
                style="
                    border: none;
                    position: absolute;
                    top: 0;
                    height: auto;
                    width: 100%;
                "
                loop
                muted
                autoplay
                oncanplay="this.play()"
                onloadedmetadata="this.muted = true"
                playsinline
                webkit-playsinline
            >
                <source src="{{ data?.banner?.video }}" type="video/webm" />
                <!-- <source
            [src]="convertWebToMp4(data?.banner?.video)"
            type="video/mp4"
            /> -->
            </video>
        </div>
        <div
            *ngIf="data?.banner?.layout === '1-col-offer-h131-strip-banner'"
            class="offer-extra-container"
            fxShow.gt-xs="false"
            [ngStyle.xs]="{ paddingTop: '0%' }"
            (click)="
                data?.banner.link.includes('http://')
                    ? externalLinksRedirect(data?.banner.link)
                    : processLink.emit(data?.banner.link)
            "
        >
            <picture
                class="offer-extra-strip"
                *ngIf="!data?.banner?.video"
                [ngClass]="{ 'image-offer-shimmer': showShimmer }"
            >
                <img
                    class="offer-extra-strip__image"
                    src="{{ data?.banner?.image }}"
                    appImageCDN
                />
            </picture>
            <video
                *ngIf="data?.banner?.video"
                poster="{{ data?.banner?.image }}"
                style="
                    border: none;
                    position: absolute;
                    top: 0;
                    height: auto;
                    width: 100%;
                "
                loop
                muted
                autoplay
                oncanplay="this.play()"
                onloadedmetadata="this.muted = true"
                playsinline
                webkit-playsinline
            >
                <source src="{{ data?.banner?.video }}" type="video/webm" />
                <!-- <source
            [src]="convertWebToMp4(data?.banner?.video)"
            type="video/mp4"
            /> -->
            </video>
        </div>
        <div
            *ngIf="data?.timerStrip?.layout === 'timer-strip' && isTimerStrip"
            class="timer-strip"
            fxShow.gt-xs="false"
            [ngStyle.xs]="{ paddingTop: '0%' }"
        >
            <picture
                class="timer-extra-strip"
                [ngClass]="{ 'image-offer-shimmer': showShimmer }"
            >
                <img
                    class="timer-extra-strip__image"
                    src="{{ data?.timerStrip?.banner?.image }}"
                    appImageCDN
                />
            </picture>

            <div
                [fxFlex]="getFlex(data?.timerStrip)"
                style="background-color:{{
                    data?.timerStrip?.banner?.background
                }}"
                [fxFlexOrder]="getFlexOrder(data?.timerStrip)"
                fxLayoutAlign="center center"
                fxLayout="row"
                class="timer-section"
            >
                <p></p>
                <div class="headline">
                    <h1 style="color:{{ data?.timerStrip?.title?.color }}">{{
                        data?.timerStrip?.title?.text
                    }}</h1>
                </div>
                <div class="countdown">
                    <ul style="color:{{ data?.timerStrip?.title?.color }}">
                        <li *ngIf="day >= 1"
                            ><span>{{ day | number: '2.0-0' }}</span
                            >Day</li
                        >
                        <li *ngIf="day >= 1" class="bullets">:</li>
                        <li
                            ><span>{{ hours | number: '2.0-0' }}</span
                            >Hours</li
                        >
                        <li class="bullets">:</li>
                        <li
                            ><span>{{ mins | number: '2.0-0' }}</span
                            >Minutes</li
                        >
                        <span *ngIf="day < 1">
                            <li class="bullets">:</li>
                            <li
                                ><span>{{ seconds | number: '2.0-0' }}</span
                                >Seconds</li
                            >
                        </span>
                    </ul>
                </div>
            </div>
        </div>
        <h1
            *ngIf="data.isHeaderVisible()"
            class="page-title"
            [ngClass.gt-xs]="{ 'desktop-cp-title': fullPage }"
            ngClass.xs="mob-cp-title"
        >
            {{ data.text }}
        </h1>
        <mat-tab-group
            *ngIf="data.feed.length > 1"
            [ngStyle.xs]="{ paddingLeft: '15px' }"
        >
            <mat-tab *ngFor="let item of data.feed">
                <ng-template mat-tab-label>
                    <a
                        class="tab-header"
                        (click)="processLink.emit(item?.link)"
                    >
                        {{ item?.text }}
                    </a>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <mat-nav-list
            fxLayout="column"
            fxLayout.gt-sm="row"
            style="padding-top: 0"
            [ngStyle.xs]="{
                paddingBottom: display === 'vertical' ? '30px' : ''
            }"
        >
            <a
                [routerLink]="[item?.link | pageIdFromLink]"
                [queryParams]="item?.link | queryParamFromLink"
                *ngFor="
                    let item of (data?.feed)[activeTab]?.items;
                    let i = index
                "
                (click)="processLink.emit(item?.link)"
                (mouseover)="
                    processLink.emit(item?.hover?.link);
                    itemSelected(item?.title.text)
                "
                class="list-item"
                fxLayout="row"
                fxLayout.gt-xs="column"
                fxLayout.lt-md="column"
                fxLayoutAlign="space-between center"
                [ngStyle.gt-xs]="{
                    marginBottom: item.icon ? '7px' : 'none'
                }"
            >
                <img
                    fxShow
                    fxShow.xs="false"
                    *ngIf="item?.icon"
                    style="width: 41px; padding: 0 12.5px"
                    src="{{ item?.icon }}"
                    alt="{{ item?.title?.text }}"
                />
                <div
                    fxLayout="row"
                    style="width: 100%"
                    fxLayoutAlign="space-between center"
                    [ngStyle.gt-sm]="{ height: '100%' }"
                    [ngStyle.xs]="{
                        width: '100%',
                        height: '140px',
                        background: item.background.color,
                        margin: i == 0 ? '0px' : '12px 0px 0px'
                    }"
                >
                    <span
                        class="list-title"
                        fxLayout.xs="column"
                        [ngClass.lt-md]="{ 'list-first-mobile': 'true' }"
                        [ngClass]="{
                            'title-wrap': display === 'vertical',
                            'title-selected':
                                display === 'horizontal' &&
                                item.title.text === itemClicked,
                            'list-title-horizontal': display === 'horizontal'
                        }"
                        [ngStyle.xs]="{
                            paddingLeft: '32px',
                            lineHeight: item.icon ? '17px' : '22px'
                        }"
                        style="color:{{ item.title.color }}"
                    >
                        <!--                        {{ item.title.text }}-->
                        <img
                            fxShow
                            fxShow.gt-xs="false"
                            *ngIf="item?.icon"
                            [ngStyle]="{
                                height: item?.title?.text ? '10px' : '33px'
                            }"
                            src="{{ item?.icon }}"
                            alt="{{ item?.title?.text }}"
                        />
                        <var
                            style="font-style: normal"
                            *ngIf="item?.title?.text"
                        >
                            {{ item?.title?.text }}
                            <sup
                                *ngIf="item?.newLaunch"
                                style="
                                    color: #c21122;
                                    font-size: 12px;
                                    font-family: 'Roboto Regular', sans-serif;
                                "
                                >New</sup
                            ></var
                        >
                    </span>
                    <div
                        style="
                            height: 140px;
                            width: 140px;
                            display: flex;
                            align-items: center;
                        "
                        ngStyle.xs="margin-right: 10px"
                        fxShow="true"
                        fxShow.gt-sm="false"
                    >
                        <img
                            *ngIf="item?.image"
                            src="{{ item.image }}"
                            appImageCDN
                            alt=""
                            [ngClass]="{
                                fullPage: 'desktop-cp-img',
                                'hide-cp-img': display === 'horizontal'
                            }"
                            class="circle-style-image"
                        />
                    </div>
                </div>

                <!--<div
                    fxShow
                    fxShow.gt-sm="false"
                    *ngIf="
                        data?.items!![itemClicked]?.title.text ===
                        item.title.text
                    "
                    [ngStyle]="{
                        'box-shadow': 'none'
                    }"
                ></div> -->
                <!--<hr
                    fxShow="true"
                    fxShow.gt-xs="false"
                    style="width: 100%; opacity: 1"
                />-->
            </a>
        </mat-nav-list>
        <div
            class="category-footer-content"
            *ngIf="!!footerContent"
            [innerHTML]="footerContent"
        >
        </div>
    </div>
    <app-error-page *ngIf="errors.length > 0" [error]="errors"></app-error-page>
</div>
