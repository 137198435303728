import { Pipe, PipeTransform } from '@angular/core';
import { RouteExecutor } from '../../@core/application-router/route.executor';
import { ApplicationRouterService } from '../../@core/application-router/application-router.service';
import { AnalyticsService } from '../../@core/analytics.service';
import { Params } from '@angular/router';

@Pipe({
    name: 'queryParamFromLink',
})
export class QueryParamsFromLinkPipe extends RouteExecutor
    implements PipeTransform {
    constructor(
        applicationRouterService: ApplicationRouterService,
        private analyticsService: AnalyticsService
    ) {
        super(applicationRouterService);
    }

    hrefLink = {};

    transform(value: string): Params | null {
        this.processLink(value);
        return this.hrefLink;
    }

    executePageLink(
        pageId: string,
        query: { [p: string]: string | string[] },
        params?: any
    ) {
        const route = pageId + '?f=' + query?.f;
        const trId = this.analyticsService.setMapValues(
            params?.tracking,
            route
        );
        query = this.getQueryParams(query);
        // console.log(this.analyticsService.isBot(window.navigator.userAgent));
        if (!this.analyticsService.isBot(window.navigator.userAgent)) {
            //  query = { ...query, trId };
        }

        this.hrefLink = query;
    }

    getQueryParams(query: { [p: string]: string | string[] }) {
        delete query?.refresh;
        delete query?.transition;
        delete query?.transient;
        delete query?.popup;
        delete query?.tr;
        return query;
    }
}
