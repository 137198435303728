import { Style } from '../@shared/models/style.model';
import { Clickable } from '../@shared/models/clickable.model';
import { CText } from '../@shared/models/text.model';
import { Iconable } from '../@shared/models/iconable.model';

export interface IProductVariantFeed {
    title?: string;
    layout: string;
    text: string;
    link: string;
    styles: Style[];
    active: boolean;
    icon?: string;
}

export class DPProductVariant {
    layout: string;
    feed: IProductVariantFeed[];
    comment: string;

    constructor() {
        this.layout = '';
        this.feed = [];
        this.comment = '';
    }
}
export interface IProductInfo {
    images?: Array<string>;
    layout: string;
    link?: string;
    text?: string;
    banners?: Array<object>;
    icon?: string;
    isExpanded?: boolean;
}

export interface HeaderData {
    icon: string;
    link: string;
    count?: number;
}

export interface IPrefix {
    text: string;
    color: string;
}

export class DPData {
    id?: string;
    zestMoney?: boolean;
    back?: {
        icon: string;
        link: string;
    };
    carousel: {
        layout: string;
        feed: Array<{
            type: string;
            url: string;
        }>;
        video?: Iconable;
        wishlist?: Iconable;
    };
    details: {
        title: CText;
        subtitle: CText;
        price: CText;
        priceDescription: { text: string };
        mrp: CText | null;
        action: Clickable;
        info: Array<IProductInfo>;
        memberPrice?: {
            background: string;
            prefix: IPrefix;
            icon: string;
            suffix: IPrefix;
        };
        deliveryCheck?: {
            button: Clickable;
            message: [];
            placeHolder: string;
        };
        easyPayment?: {
            text: string;
            paymentOption: {
                icon: string;
                prefix: string | null;
                suffix: string | null;
                link: string | null;
            }[];
        };
        preOrderPriceV2?: {
          price: CText;
          description: CText;
        } | null;
        preOrderPriceDescription?: CText | null;
    };
    cta: Clickable;
    offer: {
        title: CText;
        background: {
            color: string;
        };
        foreground: {
            icon: string;
            images: string;
            texts: Array<{
                color: string;
                leftIcon: string | null;
                rightIcon: string | null;
                text: string;
            }>;
        };
    };
    header: {
        cart: HeaderData;
        share: HeaderData;
    };
    giftWrapStrip: {
        background: string;
        prefixIcon: string;
        id: string;
        suffixIcon: {
            icon: string;
            link: string;
        };
        title: {
            color: string;
            text: string;
        };
    };
    seo?: {
        meta: {
            title: string;
            description?: string;
            keywords?: string;
        };
        faq: Array<{
            ans: string;
            que: string;
        }>;
        footerContent: string;
        canonical: string;
        langAlternates: Array<{ hreflang: string; href: string }>;
        ogImage: string;
        isProductSnippetVisible?: boolean;
        breadCrumbs?: Array<any>;
        productDetail: {
            brand: string;
            image: string;
            price: number;
            sku: string;
            status: string;
            description: string;
        };
    };
    productAddOn: ADDONData | undefined;

    constructor() {
        this.back = {
            icon: '',
            link: '',
        };
        this.carousel = {
            layout: '',
            feed: [],
            video: { icon: '', link: '' },
            wishlist: { icon: '', link: '' },
        };
        this.details = {
            title: { text: '' },
            subtitle: { text: '' },
            priceDescription: { text: '' },
            price: { text: '' },
            mrp: { text: '' },
            action: { text: '', link: '' },
            info: [],
            easyPayment: { text: '', paymentOption: [] },
        };
        this.cta = { text: '', link: '' };
        this.offer = {
            title: {
                text: '',
                color: '',
            },
            background: {
                color: '',
            },
            foreground: {
                icon: '',
                images: '',
                texts: [
                    { text: '', color: '', leftIcon: null, rightIcon: null },
                ],
            },
        };
        this.giftWrapStrip = {
            background: '',
            prefixIcon: '',
            id: '',
            suffixIcon: {
                icon: '',
                link: '',
            },
            title: {
                color: '',
                text: '',
            },
        };
        this.header = {
            cart: {
                icon: '',
                link: '',
            },
            share: {
                icon: '',
                link: '',
            },
        };
    }
}

export class ADDONData {
    title: CText;
    variants: ProductVariant[];
    suffixIcon: any;
    constructor() {
        this.title = {
            color: '',
            text: '',
        };
        this.variants = [];
    }
}
export class ProductVariant {
    activeIndex: number;
    feed: ProductFeed[];
    image?: string;
    title?: CText;
    price?: CText;
    checkBox?: {
        link: string;
        isChecked?: boolean;
    };
    cta?: {
        icon: string;
        id: string;
        link: string;
        text: string;
    };

    constructor() {
        this.activeIndex = 0;
        this.feed = [];
        this.image = '';
        this.title = {
            color: '',
            text: '',
        };
        this.price = {
            color: '',
            text: '',
        };
        this.checkBox = {
            link: '',
            isChecked: false,
        };
    }
}

export class ProductFeed {
    active: boolean;
    title: CText;
    sku: string;
    price: CText;
    image: string;
    link: string;
    variantImage: string | null;
    cssClass: boolean;
    selected: any;

    constructor() {
        this.active = true;
        this.title = {
            color: '',
            text: '',
        };
        this.sku = '';
        this.price = {
            color: '',
            text: '',
        };
        (this.image = ''), (this.link = ''), (this.variantImage = '');
        this.cssClass = false;
    }
}
