export const environment = {
  production: true,
  host: "https://api.dailyobjects.com",
  cdnHost: "https://api-cdn.dailyobjects.com",

  auth: {
    mechanism: "mobile",
    amplify: {
      userPoolId: "ap-south-1_hPGkQCYGO",
      userPoolWebClientId: "75nanbhlttrqe23dutkerof37r",
      region: "ap-south-1",
    },
  },
  logging: {
    apm: {
      endpoint: "https://apm-logs.dailyobjects.com",
    },
  },
  appVersion: require("../../package.json").version,
  firebaseConfig: {
    apiKey: "AIzaSyChS5HhYCKLaun7hTB-mYLLD18lxh1LpLg",
    authDomain: "daily-objects.firebaseapp.com",
    databaseURL: "https://daily-objects.firebaseio.com",
    projectId: "daily-objects",
    storageBucket: "daily-objects.appspot.com",
    messagingSenderId: "1077537956879",
    appId: "1:1077537956879:web:04967a5ae02fdd8783af7a",
    measurementId: "G-DK562NVEZ1",
  },
  name: "prod-marche-web",
  payments: {
    paytm: {
      url: "https://securegw.paytm.in/theia/processTransaction",
    },
  },
  trustedWebsiteCertificate: false,
  WizzySearch: {
    endpoint: "https://api.wizzy.ai/v1/products/search",
    StoreID: "f872f454b4c111ecaab80a0c8095feae",
    APIKey:
      "STIxMXFhM2EyYVZSbjR3V2V6Qm84WXRjVDBDQTBRVlNacVJreFUxYU8wRGlDQ1VLbzBIV0xNTTJob1Z1Vjh0Ynh4MWNJQ2dscGpVU2ZSeWlNbUluK3c9PQ==",
  },
  zest: {
    Merchantid: 'e973d347-5c7c-4138-b033-7fabfdec52a3',
    PaymentGatewayId: 'df430858-087f-4cbe-aabd-9a92d9c6ba3a',
  },
  nector:{
    APIKey:'f01618d3fc729daf79d3565faffe728b5b1729865e7a1184bbfa53e934560208'
  },
  mixpanel: {
        token: '50d5ef6ffa3a5c080476108272670019',
  },
};
